//auth
export const POST_LOGIN = "token/";
export const SOCIAL_LOGIN = "/social-login";
export const POST_REGISTER = "start-chat/";


//user settings
export const GET_PROFILE_DETAILS = "/profile-details";
export const UPDATE_ETTINGS = "/update-user-settings";

// profile 
export const GET_PROFILE = "accounts/";
export const CREATE_USER_PROFILE = "accounts/";
export const EDIT_USER_PROFILE = "accounts/";
export const DELETE_USER_PROFILE = "accounts/";


// contacts
export const GET_USERS = "users/";
// export const DELETE_BOOKMARK = "/bookmarks-delete";
// export const UPDATE_BOOKMARK = "/bookmarks-update";

// chats
export const GET_ADMINS = "admins/";
export const GET_CHAT_USER_DETAILS = "contacts/";
// export const CREATE_CONVERSATION = "conversations/";
export const GET_CHAT_USER_CONVERSATIONS = "conversations/";
export const RECEIVE_MESSAGE_FROM_USER = "receive-message-fromuser/";

export const SEND_MESSAGE = "messages/";
export const MESSAGE_RECEIVED = "message-received/";                             // PATCH request
export const READ_MESSAGE = "message-read/";                                     // PATCH request
export const DELETE_MESSAGE = "delete-message/";
export const DELETE_USER_MESSAGES = "conversations/";
export const FORWARD_MESSAGE = "forwarded-message/";
export const TOGGLE_FAVOURITE_CONTACT = "/toggle-favourite-contact";
export const GET_ARCHIVE_CONTACT = "/get-archive-contacts";
export const TOGGLE_ARCHIVE_CONTACT = "/toggle-archive-contact";
export const READ_CONVERSATION = "conversation-read/";                   // PATCH request
export const DELETE_IMAGE = "/user-delete-img";