import { APIClient } from "./apiCore";
import * as url from "./urls";

const setLoggedInUser = (user: any) => {
  sessionStorage.setItem("authUser", JSON.stringify(user));
  console.log("2. auth -- setLoggedInUser")
};

const api = new APIClient();



  //Login user
const postLogin = (data: any) => api.create(url.POST_LOGIN, data);

// Register Method
const postRegister = (data: any) => {
  return api.create(url.POST_REGISTER, data);
};


// postSocialLogin
const postSocialLogin = (data: any) => api.create(url.SOCIAL_LOGIN, data);

export {
  setLoggedInUser,
  postLogin,
  postRegister,
  postSocialLogin,
};
