import React, { useEffect } from "react";
import { useRedux  } from "../../../hooks/index";


// component
import ChatUser from "./ChatUser";

interface AdminsProps {
  users: Array<any>;
  onlineUsers: Record<number | string, boolean>;
  selectedChat: string | number;
  onSelectChat: (id: number | string) => void;
}
const Admins = ({ users, onlineUsers, selectedChat, onSelectChat }: AdminsProps) => {
  const { useAppSelector } = useRedux();

  const { admins } = useAppSelector(state => ({
    admins: state.Chats?.admins,
  }));

  const onAdminId = admins[0]?.id;
  console.log("onAdminId", onAdminId)

  useEffect(() => {
    if (admins && admins.length > 0) {
      onSelectChat(onAdminId);
    }
  }, [onSelectChat]);
  
  return (
    <>
      <div className="chat-message-list">
        <ul className="list-unstyled chat-list chat-user-list">
          {(admins || []).map((user: any, key: number) => (
            <ChatUser
              user={user}
              key={key}
              isOnline={onlineUsers[user.id] || false}  // <-- pass the online status of this user
              selectedChat={selectedChat}
              onSelectChat={onSelectChat}
            />
          ))}
        </ul>
      </div>
    </>
  );
};

export default Admins;
