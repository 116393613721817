//root
import Root from "../pages/Root/index";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// dashboard
import Dashboard from "../pages/Dashboard/index";

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const publicRoutes: Array<RouteProps> = [
  { path: "/auth-register", component: <Register /> },
  { path: "/logout", component: <Logout /> },
];

const privateRoutes: Array<RouteProps> = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/",  component: <Root /> },
];

export { publicRoutes, privateRoutes };
