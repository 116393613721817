import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import { Core_URL } from "../config";
import { useEffect } from 'react';

interface AuthUserData {
  access: string;
  refresh: string;
}

// default
axios.defaults.baseURL = Core_URL.API_URL;

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

const setAuthorizationHeader = (accessToken: string) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  console.log("3. apiCore -- setLoggedInUser")
};


// const authUserString = sessionStorage.getItem("authUser");
// const authUser: AuthUserData | null = authUserString ? JSON.parse(authUserString) : null;
// const accessToken = authUser?.access;


let isRefreshingToken = false;
let refreshPromise: Promise<boolean> | null = null;


async function handleTokenRefresh(): Promise<boolean> {
  
  // If a token refresh is already in progress, wait for it to complete
  if (isRefreshingToken) {
    // Return the existing promise to avoid concurrent refresh requests
    return refreshPromise!;
  }

  isRefreshingToken = true;

  const authUserString = sessionStorage.getItem("authUser");
  const authUser: AuthUserData | null = authUserString ? JSON.parse(authUserString) : null;
  const accessToken = authUser?.access;

  if (accessToken) {
    const decodedToken = JSON.parse(atob(accessToken.split('.')[1]));
    const currentTime = Date.now() / 1000;

    if (decodedToken.exp < currentTime) {
      try {
        const response = await axios.post("/token/refresh/", {
          refresh: authUser?.refresh,
        }) as { access: string, refresh: string }; // Use type assertion here -- newly added;

        const newAccessToken = response.access;
        const newRefreshToken = response.refresh;
        const updatedAuthUser = {
          ...authUser,
          access: newAccessToken,
          refresh: newRefreshToken,
        };
        sessionStorage.setItem("authUser", JSON.stringify(updatedAuthUser));

        // Set token header with new access token
        axios.defaults.headers.common["Authorization"] = `Bearer ${newAccessToken}`;
        // console.log("newAccessToken", newAccessToken)
        isRefreshingToken = false;
        refreshPromise = null;

        return true;
      } catch (error) {
        // console.log(error);

        isRefreshingToken = false;
        refreshPromise = null;

        // If refreshing token fails, log out user
        sessionStorage.removeItem("authUser");
        window.location.href = "/auth-register/";
        return false;
      };

    } else {
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
      isRefreshingToken = false;
      refreshPromise = null;
      return true; // Access token is still valid
    }
  } else {
    // Handle the case when accessToken is undefined or null
    // console.log("Access token is undefined or null");
    // You may want to perform some error handling here
    isRefreshingToken = false;
    refreshPromise = null;
    return false;
  }
}



// intercepting to capture errors
axios.interceptors.response.use(
  async (response: AxiosResponse) => {
    return response?.data ?? response;
  },
  async (error: AxiosError) => {
    // console.log(error?.response?.status)
    // Handle undefined errors
    if (typeof error.response === 'undefined') {   
      alert(
        'We apologize, but our service is currently not available in your country.' + 
        'Please check back later.'
      );      
      // alert(
      //   'We are currently undergoing maintenance.' +
      //   'Please try logging in again later.'
      // );      
      // alert(
      //   'A server/network error occurred. ' +
      //   'Sorry about this - we will get it fixed shortly.'
      // );
      return Promise.reject(error);
    }

    // Handle 401 error
    if (error?.response?.status === 401 ) {
      const refreshSuccessful = await handleTokenRefresh();
    }
  
    // Handle 403 error
    if (error?.response?.status === 403) {
      const refreshSuccessful = await handleTokenRefresh();
      if (!refreshSuccessful) {
        setTimeout(() =>{
          alert(
            "You do not have permission to access this content."
          );
        }, 2000);
      }
    }
    // Handle other errors
    interface ErrorResponse {
      detail?: string;
      // ... include other properties you might expect in the error
    }
    if (
      error?.response?.status === 500 &&
      (error?.response?.data as ErrorResponse).detail === "Signature has been expired"
    ) {
      const refreshSuccessful = await handleTokenRefresh();

      const authUserString = sessionStorage.getItem("authUser");
      const authUser = authUserString ? JSON.parse(authUserString) : null;
      const accessToken = authUser?.access;
      
      if (refreshSuccessful) {
        // Retry original request with updated access token
        const originalRequest: AxiosRequestConfig = error.config;
        originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;
        return axios(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);


//Wrap the main API request logic in an async function
async function performApiRequest(requestFunction: any) {
  try {
    // Attempt the original API request
    const response = await requestFunction();

    // Handle successful response
    return response;
  } catch (error: any) {
    // Handle token-related errors
    if (error.response && (error.response.status === 401 || error.response.status === 403 || error.response.status === 500)) {
      try {
        const refreshSuccessful = await handleTokenRefresh();
        // console.log("refreshSuccessful from performApiRequest function", refreshSuccessful)
        if (refreshSuccessful) {
          // Retry the original API request after token refresh
          return await requestFunction();
        } else {
          // Handle the case where token refresh failed
          // You may want to log out the user or handle this scenario appropriately
          return Promise.reject(error);
        }
      } catch (refreshError) {
        // Handle any errors that occur during token refresh
        return Promise.reject(refreshError);
      }
    } else {
      // Handle other errors
      return Promise.reject(error);
    }
  }
}


const refreshAuthorizationHeader = () => {
  const authUserString = sessionStorage.getItem("authUser");
  const authUser = authUserString ? JSON.parse(authUserString) : null;
  const accessToken = authUser?.access;

  if (accessToken) {
    setAuthorizationHeader(accessToken);
  }
  // console.log("3. apiCore -- setLoggedInUser")
};

class APIClient {  

  create = async (url: string, data: any): Promise<AxiosResponse> => {
    refreshAuthorizationHeader();
    return performApiRequest(async () => {
      return axios.post(url, data);
    });
  };

  get = async (url: string, params?: any): Promise<AxiosResponse> => {
    // console.log("4. GET -- setLoggedInUser")
    // console.log("5. GET -- axios.defaults.headers.common", axios.defaults.headers.common["Authorization"])
    return performApiRequest(async () => {
      if (params) {
        const paramKeys = Object.keys(params).map(key => `${key}=${params[key]}`);
        const queryString = paramKeys.length ? paramKeys.join('&') : '';
        return axios.get(`${url}?${queryString}`);
      } else {
        return axios.get(url);
      }
    });
  };

  update = async (url: string, data: any): Promise<AxiosResponse> => {
    return performApiRequest(async () => {
      const response = await axios.patch(url, data);
      return response;
    });
  };

  put = async (url: string, data: any): Promise<AxiosResponse> => {
    return performApiRequest(async () => {
      return axios.put(url, data);
    });
  };

  delete = async (url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse> => {
    return performApiRequest(async () => {
      if (data !== undefined) {
        config = config || {};
        config.data = data;
      }
      return axios.delete(url, config);
    });
  };
  // delete = async (url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> => {
  //   return performApiRequest(async () => {
  //     return axios.delete(url, config);
  //   });
  // };

  updateWithFile = (url: string, data: any): Promise<AxiosResponse> => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }
    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    //return axios.put(url, formData, config);
    return axios.put(url, formData);
  };

  createWithFile = (url: string, data: any): Promise<AxiosResponse> => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }
    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    //return axios.post(url, formData, config);
    return axios.post(url, formData);
  };
}

const getLoggedinUser = () => {
  const user = sessionStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export { setAuthorizationHeader, APIClient, getLoggedinUser };