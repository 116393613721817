import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import { AuthRegisterActionTypes } from "./types";
import {
  authRegisterApiResponseSuccess,
  authRegisterApiResponseError,
} from "./actions";

//Include Both Helper File with needed methods
import { postRegister, setLoggedInUser } from "../../api/index";
import { setAuthorizationHeader } from "../../api/apiCore";



// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user } }: any) {
  try {
    const response: Promise<any> = yield call(postRegister, user);

    // Wait for the promise to resolve
    const responseData = yield response;

    // Store user data in sessionStorage
    setLoggedInUser(responseData);
    console.log("1. saga -- setLoggedInUser", responseData.access)

    // Set Authorization header for Axios
    if (responseData.access) {
      setAuthorizationHeader(responseData.access);
    }

    yield put(
      authRegisterApiResponseSuccess(AuthRegisterActionTypes.REGISTER_USER, response)
    );
  } 
  catch (error: any) {
    yield put(
      authRegisterApiResponseError(AuthRegisterActionTypes.REGISTER_USER, error)
    );
  }
}

function* logoutUser() {
  try {
    sessionStorage.removeItem("authUser");
    yield put(
      authRegisterApiResponseSuccess(AuthRegisterActionTypes.LOGOUT_USER, true)
    );
    
  } catch (error: any) {
    yield put(
      authRegisterApiResponseError(AuthRegisterActionTypes.LOGOUT_USER, error)
    );
  }
}

export function* watchUserRegister() {
  yield takeEvery(AuthRegisterActionTypes.REGISTER_USER, registerUser);
}

export function* watchUserLogout() {
  yield takeEvery(AuthRegisterActionTypes.LOGOUT_USER, logoutUser);
}

function* registerSaga() {
  yield all([
    fork(watchUserRegister),
    fork(watchUserLogout),
  ]);
}

export default registerSaga;
