import React, { useEffect, useState, useRef, useCallback } from "react";

// hooks
import { useRedux } from "../../../hooks/index";

// actions
import {
  toggleUserDetailsTab,
  getChatUserConversations,
  receiveMessageFromUser,
  deleteMessage,
  deleteUserMessages,
} from "../../../redux/actions";

// components
import UserHead from "./UserHead";
import Conversation from "./Conversation";
import ChatInputSection from "./ChatInputSection/index";

// interface
import { MessagesTypes } from "../../../data/datatypes";

import { w3cwebsocket as W3CWebSocket } from 'websocket'


interface IndexProps {

  chatUserDetails: any;
}
const Index = (props: IndexProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();

  const {
    users,
    admins,
    chatUserDetails,
    chatUserConversations,
    isUserMessageSent,
    isMessageDeleted,
    isMessageForwarded,
    isUserMessagesDeleted,
    isImageDeleted,
  } = useAppSelector(state => ({
    users: state.AllUsers.users,
    admins: state.Chats.admins[0],
    chatUserDetails: state.Chats.chatUserDetails,
    chatUserConversations: state.Chats.chatUserConversations,
    isUserMessageSent: state.Chats.isUserMessageSent,
    isMessageDeleted: state.Chats.isMessageDeleted,
    isMessageForwarded: state.Chats.isMessageForwarded,
    isUserMessagesDeleted: state.Chats.isUserMessagesDeleted,
    isImageDeleted: state.Chats.isImageDeleted,
  }));

  const onOpenUserDetails = () => {
    dispatch(toggleUserDetailsTab(true));
  };

  /*
  reply handeling
  */
  const [replyData, setReplyData] = useState<
    null | MessagesTypes | undefined
  >();
  const onSetReplyData = (reply: null | MessagesTypes | undefined) => {
    setReplyData(reply);
  };

  //console.log('70 -- websocket -- users.id', users?.id)
  const conversationId = users?.id;
  const socketClientRef = useRef<W3CWebSocket | null>(null);
  const MAX_RETRIES = 5;

  const connectWebSocket = useCallback(() => {
    let retries = 0;  // Moved inside the function

    const handleOpen = () => {
      console.log('Message WebSocket client connected');
      retries = 0;
    };

    const handleMessage = (message: any) => {
      const receivedData = JSON.parse(message.data);
      //console.log("message", message);

      if (message.type === 'message') {
        dispatch(receiveMessageFromUser(receivedData.message));
      }
    };

    const handleError = (error: any) => {
        console.error('WebSocket error:', error);
    };

    const handleClose = () => {
      console.log('WebSocket Closed! Attempting to reconnect...');
      if (retries < MAX_RETRIES) {
        retries++;
        setTimeout(connectWebSocket, retries * 1000);
      } else {
        console.error("Max retries reached. Not attempting to reconnect.");
      }
    };

    socketClientRef.current = new W3CWebSocket(`ws://127.0.0.1:8000/ws/chat/${conversationId}/`);
    socketClientRef.current.onopen = handleOpen;
    socketClientRef.current.onmessage = handleMessage;
    socketClientRef.current.onerror = handleError;
    socketClientRef.current.onclose = handleClose;
  }, []);

  useEffect(() => {
    connectWebSocket();

    const handleVisibilityChange = () => {
      if (!document.hidden && (!socketClientRef.current || socketClientRef.current.readyState !== WebSocket.OPEN)) {
        console.log('Page is now visible. Attempting to reconnect...');
        setTimeout(connectWebSocket, 3000);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      socketClientRef.current?.close();
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [connectWebSocket]);

  const adminId = admins?.id;
  /*
    send message
  */
  const onSend = (data: any) => {
    let params: any = {
      conversation: conversationId,
      text: data.text && data.text,
      time: new Date().toISOString(),
      image: data.image && data.image,
      newimage: data.newimage && data.newimage,
      attachments: data.attachments && data.attachments,
      meta: {
        receiver: adminId,
        sender: conversationId,
      },
    };

    if (replyData && replyData !== null) {
        params["replyOf"] = replyData;
    }

    // Send the message through WebSocket
    socketClientRef.current?.send(JSON.stringify({
      params,
    }));
    
    setReplyData(null);
  };



  useEffect(() => {
    if (
      isUserMessageSent ||
      isMessageDeleted ||
      isMessageForwarded ||
      isUserMessagesDeleted ||
      isImageDeleted
    ) {
      dispatch(getChatUserConversations(conversationId));
    }
  }, [
    dispatch,
    isUserMessageSent,
    chatUserDetails,
    isMessageDeleted,
    isMessageForwarded,
    isUserMessagesDeleted,
    isImageDeleted,
    conversationId
  ]);

  const onDeleteMessage = (messageId: string | number) => {
    dispatch(deleteMessage(chatUserDetails.id, messageId));
  };

  const onDeleteUserMessages = () => {
    dispatch(deleteUserMessages(chatUserDetails.id));  // now it is set to delete user related whole conversation
  };


  return (
    <>
      <UserHead
        chatUserDetails={chatUserDetails}
        onDelete={onDeleteUserMessages}
      />
      <Conversation
        chatUserConversations={chatUserConversations}
        chatUserDetails={admins}  //  last change from chatUserDetails to admins 
        onDelete={onDeleteMessage}
        onSetReplyData={onSetReplyData}
      />
      <ChatInputSection
        onSend={onSend}
        replyData={replyData}
        onSetReplyData={onSetReplyData}
        chatUserDetails={chatUserDetails}
      />
    </>
  );
};

export default Index;
