import { useState, useEffect } from "react";

// hooks
import { useRedux } from "../hooks/index";

// api
import { getLoggedinUser } from "../api/apiCore";

//utils
import { divideByKey } from "../utils";


const useProfile = () => {
  const userProfileSession = getLoggedinUser();
  const [loading] = useState(userProfileSession ? false : true);
  const [userProfile, setUserProfile] = useState(
    userProfileSession ? { ...userProfileSession } : null
  );
  useEffect(() => {
    const userProfileSession = getLoggedinUser();
    setUserProfile(
      userProfileSession ? { ...userProfileSession } : null
    );
  }, []);

  return { userProfile, loading };
};



const useContacts = () => {
  const { useAppSelector } = useRedux();

  const { contactsList } = useAppSelector(state => ({
    contactsList: state.AllUsers.contacts,
  }));

  const [categorizedContacts, setCategorizedContacts] = useState([]);

  useEffect(() => {
    if (contactsList.length > 0) {
      const formattedContacts = divideByKey("first_name", contactsList);
      setCategorizedContacts(formattedContacts);
    }
  }, [contactsList]);

  const totalContacts = (categorizedContacts || []).length;
  return { categorizedContacts, totalContacts };
};


const useConversationUserType = () => {
  // global store
  const { useAppSelector } = useRedux();

  const { chatUserDetails } = useAppSelector(state => ({
    chatUserDetails: state.Chats.chatUserDetails,
  }));

  return { chatUserDetails };
};
export { useProfile, useContacts, useConversationUserType };
