import React, { useState } from "react";
import { Button, UncontrolledTooltip, ButtonDropdown, DropdownToggle, DropdownMenu, Form } from "reactstrap";
import EmojiPicker from "emoji-picker-react";

interface StartButtonsProps {
  onToggle: () => void;
  onChange: (value: string) => void;
  text: null | string;
}

const StartButtons = ({ onToggle, onChange, text }: StartButtonsProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onToggleEmoji = () => {
    setIsOpen(!isOpen);
  };

  const onEmojiClick = (event: any) => {
    onChange(text + event.emoji);
  };

  return (
    <div className="chat-input-links me-md-2">
      <div className="links-list-item" id="more-menu">
        <Button
          type="button"
          className="btn btn-link text-decoration-none btn-lg waves-effect"
          onClick={onToggle}
          color="none"
        >
          <i className="bx bx-dots-horizontal-rounded align-middle"></i>
        </Button>
      </div>
      <UncontrolledTooltip target="more-menu" placement="top">
        More
      </UncontrolledTooltip>
      <div className="links-list-item" id="emoji">
        <ButtonDropdown isOpen={isOpen} toggle={onToggleEmoji}>
          <DropdownToggle
            id="emoji-toggle"
            color="link"
            className="text-decoration-none font-size-24 btn-lg waves-effect"
            caret
          >
            <i className="bx bx-smile align-middle"></i>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <EmojiPicker onEmojiClick={onEmojiClick} />
          </DropdownMenu>
        </ButtonDropdown>
        <UncontrolledTooltip target="emoji-toggle" placement="top">
          Emoji
        </UncontrolledTooltip>
      </div>
    </div>
  );
};

export default StartButtons;
