// types
import { AllUsersActionTypes, AllUsersState } from "./types";

export const INIT_STATE: AllUsersState = { };

const AllUsers = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case AllUsersActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case AllUsersActionTypes.GET_USERS:
          console.log('users: action.payload.data[0]', action.payload.data[0])
          return {
            ...state,
            users: action.payload.data[0],
            isUsersFetched: true,
            getUsersLoading: false,
          };
        default:
          return { ...state };
      }

    case AllUsersActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case AllUsersActionTypes.GET_USERS:
          return {
            ...state,
            isUsersFetched: false,
            getUsersLoading: false,
          };
        default:
          return { ...state };
      }
    
    case AllUsersActionTypes.GET_USERS: {
      return {
        ...state,
        getUsersLoading: true,
        isUsersFetched: false,
      };
    }
    default:
      return { ...state };
  }
};

export default AllUsers;
