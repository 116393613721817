import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { ChatsActionTypes } from "./types";
import { chatsApiResponseSuccess, chatsApiResponseError } from "./actions";

import {
  getAdmins as getAdminsApi,
  getChatUserDetails as getChatUserDetailsApi,
  getChatUserConversations as getChatUserConversationsApi,
  sendMessage,
  messageReceived as messageReceivedApi,
  readMessage as readMessageApi,
  // receiveMessageFromUser as receiveMessageFromUserApi,
  deleteUserMessages as deleteUserMessagesApi,
  deleteMessage as deleteMessageApi,
  forwardMessage as forwardMessageApi,
  toggleFavouriteContact as toggleFavouriteContactApi,
  readConversation as readConversationApi,
  deleteImage as deleteImageApi,
} from "../../api/index";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../helpers/notifications";

//actions
import { getAdmins as getAdminsAction } from "./actions";

function* getAdmins() {
  try {
    const response: Promise<any> = yield call(getAdminsApi);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.GET_ADMINS, response)
    );
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.GET_ADMINS, error));
  }
}

function* getChatUserDetails({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(getChatUserDetailsApi, id);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.GET_CHAT_USER_DETAILS, response)
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_CHAT_USER_DETAILS, error)
    );
  }
}

function* getChatUserConversations({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(getChatUserConversationsApi, id);
    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS,
        response
      )
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS, error)
    );
  }
}

function* onSendMessage({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendMessage, data);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.ON_SEND_MESSAGE, response)
    );
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.ON_SEND_MESSAGE, error));
  }
}

function* messageReceived({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(messageReceivedApi, id);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.RECEIVE_MESSAGE, response)
    );
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.RECEIVE_MESSAGE, error));
  }
}

function* readMessage({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(readMessageApi, id);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.READ_MESSAGE, response));
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.READ_MESSAGE, error));
  }
}

// function* receiveMessageFromUser({ payload: id }: any) {
//   try {
//     const response: Promise<any> = yield call(receiveMessageFromUserApi, id);
//     yield put(
//       chatsApiResponseSuccess(
//         ChatsActionTypes.RECEIVE_MESSAGE_FROM_USER,
//         response
//       )
//     );
//   } catch (error: any) {
//     yield put(
//       chatsApiResponseError(ChatsActionTypes.RECEIVE_MESSAGE_FROM_USER, error)
//     );
//   }
// }

function* deleteMessage({ payload: { userId, messageId } }: any) {
  try {
    const response: Promise<any> = yield call(
      deleteMessageApi,
      userId,
      messageId
    );
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.DELETE_MESSAGE, response)
    );
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.DELETE_MESSAGE, error));
  }
}

function* forwardMessage({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(forwardMessageApi, data);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.FORWARD_MESSAGE, response)
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error + "");
    yield put(chatsApiResponseError(ChatsActionTypes.FORWARD_MESSAGE, error));
  }
}

function* deleteUserMessages({ payload: userId }: any) {
  try {
    const response: Promise<any> = yield call(deleteUserMessagesApi, userId);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.DELETE_USER_MESSAGES, response)
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error + "");
    yield put(
      chatsApiResponseError(ChatsActionTypes.DELETE_USER_MESSAGES, error)
    );
  }
}


function* toggleFavouriteContact({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(toggleFavouriteContactApi, id);
    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.TOGGLE_FAVOURITE_CONTACT,
        response
      )
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error + "");
    yield put(
      chatsApiResponseError(ChatsActionTypes.TOGGLE_FAVOURITE_CONTACT, error)
    );
  }
}


function* readConversation({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(readConversationApi, id);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.READ_CONVERSATION, response)
    );
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.READ_CONVERSATION, error));
  }
}

function* deleteImage({ payload: { userId, messageId, imageId } }: any) {
  try {
    const response: Promise<any> = yield call(
      deleteImageApi,
      userId,
      messageId,
      imageId
    );
    yield put(chatsApiResponseSuccess(ChatsActionTypes.DELETE_IMAGE, response));
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.DELETE_IMAGE, error));
  }
}

export function* watchGetAdmins() {
  yield takeEvery(ChatsActionTypes.GET_ADMINS, getAdmins);
}
export function* watchGetChatUserDetails() {
  yield takeEvery(ChatsActionTypes.GET_CHAT_USER_DETAILS, getChatUserDetails);
}
export function* watchGetChatUserConversations() {
  yield takeEvery(
    ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS,
    getChatUserConversations
  );
}
export function* watchOnSendMessage() {
  yield takeEvery(ChatsActionTypes.ON_SEND_MESSAGE, onSendMessage);
}
export function* watchMessageReceived() {
  yield takeEvery(ChatsActionTypes.RECEIVE_MESSAGE, messageReceived);
}
export function* watchReadMessage() {
  yield takeEvery(ChatsActionTypes.READ_MESSAGE, readMessage);
}
// export function* watchReceiveMessageFromUser() {
//   yield takeEvery(
//     ChatsActionTypes.RECEIVE_MESSAGE_FROM_USER,
//     receiveMessageFromUser
//   );
// }
export function* watchDeleteMessage() {
  yield takeEvery(ChatsActionTypes.DELETE_MESSAGE, deleteMessage);
}
export function* watchForwardMessage() {
  yield takeEvery(ChatsActionTypes.FORWARD_MESSAGE, forwardMessage);
}
export function* watchDeleteUserMessages() {
  yield takeEvery(ChatsActionTypes.DELETE_USER_MESSAGES, deleteUserMessages);
}
export function* watchToggleFavouriteContact() {
  yield takeEvery(
    ChatsActionTypes.TOGGLE_FAVOURITE_CONTACT,
    toggleFavouriteContact
  );
}
export function* watchReadConversation() {
  yield takeEvery(ChatsActionTypes.READ_CONVERSATION, readConversation);
}
export function* watchDeleteImage() {
  yield takeEvery(ChatsActionTypes.DELETE_IMAGE, deleteImage);
}

function* chatsSaga() {
  yield all([
    fork(watchGetAdmins),
    fork(watchGetChatUserDetails),
    fork(watchGetChatUserConversations),
    fork(watchOnSendMessage),
    fork(watchMessageReceived),
    fork(watchReadMessage),
    // fork(watchReceiveMessageFromUser),
    fork(watchDeleteMessage),
    fork(watchForwardMessage),
    fork(watchDeleteUserMessages),
    fork(watchToggleFavouriteContact),
    fork(watchReadConversation),
    fork(watchDeleteImage),
  ]);
}

export default chatsSaga;
